function getPositionFromBottom (element) {
  return jQuery(document).height() - element.offset().top - element.outerHeight()
}

jQuery(document).ready(function (jQuery) {
  /* global Swiper */
  Swiper('.swiper-container', {
    mode: 'horizontal',
    autoplay: 6000,
    loop: true,
    nextButton: '.teamslider-next',
    prevButton: '.teamslider-prev',
    slidesPerView: 6,
    spaceBetween: 40,
    freeMode: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  })

  var open = false
  jQuery('.main-item').on('mouseenter', function () {
    if (!open) {
      jQuery(this).find('.animate-block > .sub-menu').stop().slideDown(300)
      open = true
    }
  }).on('mouseleave', function () {
    if (open) {
      jQuery(this).find('.animate-block > .sub-menu').stop().slideUp(300)
      open = false
    }
  })

  jQuery('.header-toggle').click(function () {
    jQuery(this).toggleClass('open')
  })

  // jQuery is available as jQuery, so why not use it?
  var stickyConfig = function () {
    return {
      topSpacing: jQuery('.header-top-container').height() + 20,
      bottomSpacing: getPositionFromBottom(jQuery('.usps')) + 100
    }
  }

  jQuery('.teamimage-block').sticky(stickyConfig())

  enquire.register("screen and (max-width:768px)", {
    match: function () {
      jQuery('.teamimage-block').unstick()
    },
    unmatch: function () {
      jQuery('.teamimage-block').sticky(stickyConfig())
    }
  });


  jQuery('.sub-menu a').on('click', function (event) {
    var subMenu = jQuery(this).parent().find('.sub-menu')
    var subMenuOld = jQuery(this).parent().parent()
    if (subMenu.length <= 0) {
      return true
    }

    event.preventDefault()

    jQuery(this).closest('.sub-menu').addClass('open')
    subMenu.show()
    var newHeight = subMenu.innerHeight();

    subMenuOld.animate({height: newHeight + 'px'}, 100);

    return false
  })

  jQuery('.sub-menu .back-button').on('click', function (event) {
    event.preventDefault()
    var subMenu = jQuery(this).closest('.sub-menu')
    var subMenuOld = subMenu.parent().parent()

    subMenuOld.css('height', 'auto');

    // This is kind of bad, at least I don't like it..
    subMenuOld.removeClass('open')
    subMenu.hide()
  })
})
